

const library = {
    state: () => ({
        editLibraryData: {},
        notificationTransactionIDForLesson:0,
        notiCount:0,
        favCount:0,
        notificationTransactionIDForArticle:0,
        notificationTransactionIDForSound:0,
        notificationTransactionIDForVideo:0,
        notificationTransactionIDForEvent:0,
        isLoggedIn: false,
    }),

    getters:{
        editLibraryData(state){
            return state.editLibraryData
        },
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        notificationTransactionIDForLesson : state => state.notificationTransactionIDForLesson,
        notiCount : state => state.notiCount,
        favCount : state => state.favCount,
        notificationTransactionIDForArticle : state => state.notificationTransactionIDForArticle,
        notificationTransactionIDForSound : state => state.notificationTransactionIDForSound,
        notificationTransactionIDForVideo : state => state.notificationTransactionIDForVideo,
        notificationTransactionIDForEvent : state => state.notificationTransactionIDForEvent,
    },

    mutations:{
        setLoggedIn(state, value) {
            state.isLoggedIn = value;      
        },
        saveLibrary(state,data){
            state.editLibraryData = data
        },
        savenotificationTransactionIDForLesson(state, data){
            state.notificationTransactionIDForLesson = data;
        },
        savenotiCount(state, data){
            state.notiCount = data;
        },
        savefavCount(state,data){
            state.favCount = data;
        },
        savenotificationTransactionIDForArticle(state,data){
            state.notificationTransactionIDForArticle = data;
        },
        savenotificationTransactionIDForSound(state,data){
            state.notificationTransactionIDForSound = data;
        },
        savenotificationTransactionIDForVideo(state,data){
            state.notificationTransactionIDForVideo = data;
        },
        savenotificationTransactionIDForEvent(state,data){
            state.notificationTransactionIDForEvent = data;
        }

    }
}

export default library;