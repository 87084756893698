import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios"
import global_utilities from "./mixins/global/global_utilities";
import CountryFlag from 'vue-country-flag'
import i18n from '@/translate/i18n'
import store from './store/index';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent} from "firebase/analytics";

import SuccessDialog from '@/components/SuccessDialog.vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import VideoPlayer from '@viker-lib/video-player';
import '@viker-lib/video-player/dist/index.css';
axios.defaults.headers.common["Authorization"] =
  "Bearer " + sessionStorage.getItem("token");
Vue.prototype.$axios = axios;

Vue.component('Breadcrumbs', Breadcrumbs),
Vue.component('country-flag', CountryFlag)
Vue.component('SuccessDialog', SuccessDialog),
Vue.mixin(global_utilities);
Vue.use(VideoPlayer);

const firebaseConfig = {
  apiKey: "AIzaSyBkrXNB6N6omLg2OCpCQAmJZJD00cgIdV0",
  authDomain: "fusionseed-c367b.firebaseapp.com",
  projectId: "fusionseed-c367b",
  storageBucket: "fusionseed-c367b.appspot.com",
  messagingSenderId: "929354132948",
  appId: "1:929354132948:web:4fda469953e26e0ef0fa41",
  measurementId: "G-LPFY00R5Q1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics( );
console.log(app);


Vue.prototype.$analytics = analytics;
Vue.config.productionTip = false
window.addEventListener('beforeunload',function(){
  this.logout();
 });
new Vue({
  methods:{
    logEvent_tab(EventName){
      let self = this;
      logEvent(self.$analytics,EventName);
    },
    logout() {
      localStorage.setItem("vivek_authenticated_user_data", "");
      localStorage.setItem("vivek_token", "");
      localStorage.clear();
      sessionStorage.removeItem("token");
      this.$router.push({ path: "/login", query: { db_locale: i18n.locale } });
      let expire = "Thu, 01 Jan 1970 00:00:00 GMT;";
      document.cookie = "langprod=;" + ";expires=" + expire;
      document.cookie = "flagprod=;" + ";expires=" + expire;
      document.cookie = "titleprod=;" + ";expires=" + expire;
      document.cookie = "highlighted=;" + ";expires=" + expire;
    },
  },
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
