<template>
  <v-app>
    <Navbar
      ref="navbar_comp"
      v-show="show_navbar"
      :primaryColor="primaryColor"
      v-if="
        $route.path != '/login' && $route.path != '/course/quizWeb'
          ? true
          : false
      "
    />
    <!--  -->
    <v-main class="bg">
      <router-view
        @toggleNavBarCall="toggleNavBarCall"
        style="padding-top: 1rem !important; padding-left: 1.6rem !important"
        :primaryColor="primaryColor"
      ></router-view>
    </v-main>
  </v-app>
</template>

<style scoped lang="css">
.bg {
  background: url("~@/assets/Component 87 – 6@2x.png") no-repeat center center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transform: scale(1);
}
</style>

<script>
import Navbar from "./components/Navbar";

export default {
  name: "App",

  components: {
    Navbar,
  },

  data: () => ({
    //
    show_navbar: false,
    notiCount: 0,
    primaryColor: "#A6CC39",
  }),

  methods: {
    toggleNavBarCall(v) {
      if (v == "userLogIn") {
        this.show_navbar = true;
      } else {
        this.show_navbar = false;
      }
    },
  },
};
</script>
